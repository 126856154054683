import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'i18n'
import CloseIcon from '@components/CloseIcon'
import ChatIcon from '@components/ChatIcon'

const Drawer: React.FC<{ version: string; open: boolean; onClose: () => void }> = React.memo(
    ({ version, open, onClose }) => {
        const { t } = useTranslation()
        return (
            <>
                <aside
                    className={classNames(
                        'md:hidden transform top-0 right-0 w-3/4 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30',
                        open ? 'translate-x-0' : 'translate-x-full',
                    )}
                >
                    <div className="p-4 mb-4">
                        <CloseIcon onClick={onClose} className="w-6 text-gray-800 cursor-pointer" />
                    </div>
                    <div className="flex flex-col space-y-5 text-center text-gray-800">
                        <a href="https://fastic.com/fasticpedia-2/" className="text-lg cursor-pointer hover:underline">
                            {t('common:menu.faq')}
                        </a>
                        <a href="https://fastic.com/privacy-policy" className="text-lg cursor-pointer hover:underline">
                            {t('common:menu.policy')}
                        </a>
                        <a href="https://fastic.com/terms" className="text-lg cursor-pointer hover:underline">
                            {t('common:menu.terms')}
                        </a>
                        <div className="flex flex-col text-center items-center">
                            <ChatIcon className="w-12 text-yellow-600 mt-5" />
                            <h2 className="text-lg text-yellow-600 my-2">
                                {t([`landing:footer.need_help.${version}`, 'landing:footer.need_help.default'])}
                            </h2>
                            <p className="mt-1 mb-5 leading-snug text-sm text-gray-400 w-2/3">
                                {t([`landing:footer.contact_us.${version}`, 'landing:footer.contact_us.default'])}
                            </p>
                            <a href="mailto:support@fastic.com" className="text-yellow-600 hover:underline text-lg">
                                support@fastic.com
                            </a>
                        </div>
                    </div>
                </aside>
                <div
                    onClick={onClose}
                    className={classNames(
                        'md:hidden fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-20 ease-in-out transition-all',
                        open ? 'opacity-1 visible' : 'opacity-0 invisible',
                    )}
                ></div>
            </>
        )
    },
    (prevProps, nextProps) => prevProps.open == nextProps.open,
)

export default Drawer
