import * as React from 'react'
import { useTranslation } from 'i18n'

function CloseIconFn(props: React.SVGProps<SVGSVGElement>) {
    const { t } = useTranslation()
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
            <title>{t('common:controls.back')}</title>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
    )
}

const CloseIcon = React.memo(CloseIconFn)
export default CloseIcon
